/*Slider Style*/
.slick-slider {
  padding-bottom: 20px;
}

.slick-slide img {
  display: inline-block;
  height: 500px;
  object-fit: contain;
}

.slick-dots {
  bottom: 0;
}

.slick-dots li {
  width: 12px;
  height: 12px;
  margin-right: 2px;
  margin-left: 2px;
}

.slick-dots li button {
  width: 12px;
  height: 12px;
  padding: 0;
}

    .slick-dots li button:before {
        width: 12px;
        height: 12px;
        line-height: 12px;
        font-size: 10px;
        color: #3cc0f1;
        opacity: 1;
    }

    .slick-dots li.slick-active button::before {
        color: #0a8fdc;
        opacity: 1;
    }
